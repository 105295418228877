import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('http://localhost:8000/api/admin/settings')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`http://localhost:8000/api/admin/settings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, settingData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`http://localhost:8000/api/admin/settings/${settingData.id}`, settingData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSetting(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`http://localhost:8000/api/admin/settings/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSetting(ctx, serttingData) {
      return new Promise((resolve, reject) => {
        axios
          .post('http://localhost:8000/api/admin/settings', serttingData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
