<template>
  <div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Username -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Código"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="settingData.code"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Key"
            label-for="key"
          >
            <b-form-input
              id="key"
              v-model="settingData.key"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Valor"
            label-for="value"
          >
            <b-form-input
              id="value"
              v-model="settingData.value"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Serialización"
            label-for="serializaed"
          >
            <v-select
              v-model="settingData.serialized"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="serializedOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="serializaed"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Role -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Pais"
            label-for="country"
          >
            <v-select
              v-model="settingData.country_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="countryOptions"
              :reduce="val => val.value"
              :clearable="false"
              input-id="country"
            />
          </b-form-group>
        </b-col>

        
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="updateSetting"
    >
      Guardar cambios
    </b-button>
    <b-button
      variant="outline-secondary"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.push({name: 'config-settings-list'})"
    >
      Cancelar
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import useUsersList from '../useSettingList'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  props: {
    settingData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  methods: {
    updateSetting() {
      userStoreModule.actions.updateUser([], this.settingData).then((response) => {
        console.log(response)
        this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `Ok`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'response.data.message',
            },
          })

          this.$router.push({name: 'config-settings-list'})
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
